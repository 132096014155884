import $ from 'bianco.query';

/**
 * Scroll to a DOM node
 * @param {string} selector - element selector
 * @return {HTMLElement|null|undefined} the first element matching the selector
 */
export default function scrollToElement(selector) {
    try {
        const [element] = $(selector);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }

        return element;
    } catch (error) {
        // it was not possible to scroll to this element
        return null;
    }
}
