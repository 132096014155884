/* eslint-disable global-require */
export default [
    {
        hasNativeSupport: () => typeof Array.from === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.from'));
            }, null, 'polyfills/array-from');
        },
    },
    {
        hasNativeSupport: () => typeof Array.of === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.of'));
            }, null, 'polyfills/array-of');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.includes === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.includes'));
            }, null, 'polyfills/array-includes');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.find === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find'));
            }, null, 'polyfills/array-find');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find-index'));
            }, null, 'polyfills/array-find-index');
        },
    },
    {
        hasNativeSupport: () => !!window.URLSearchParams && (new window.URLSearchParams({ a: 1 })).toString() === 'a=1',
        polyfill(resolve) {
            require.ensure([], () => {
                ;
                resolve(require('core-js/features/url-search-params'));
            }, null, 'polyfills/url-search-params');
        },
    },
    {
        hasNativeSupport: () => ('scrollBehavior' in document.documentElement.style),
        polyfill(resolve) {
            const { polyfill } = require('smoothscroll-polyfill');
            const links = [].slice.call(document.querySelectorAll('a[href^="#"]'));

            links.forEach(link => {
                const id = link.attributes['href'].value.slice(1);
                const target = document.getElementById(id);
                link.addEventListener('click', () => {
                    target.scrollIntoView({ behavior: 'smooth' });
                });
            });

            resolve(polyfill(), 'polyfills/smoothscroll-polyfill');
        },
    },
    {
        hasNativeSupport: () => ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype),
        polyfill(resolve) {
            resolve(require('intersection-observer'), 'intersection-observer');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.fill === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.fill'));
            }, null, 'polyfills/array-fill');
        },
    },
    {
        hasNativeSupport: () => typeof Object.assign === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.assign'));
            }, null, 'polyfills/object-assign');
        },
    },
    {
        hasNativeSupport: () => typeof Object.entries === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.entries'));
            }, null, 'polyfills/object-entries');
        },
    },
];
